import { FC, useState } from 'react';
import { HeaderReduxProps } from '.';
import Link from 'next/link';
import { IconPlayCircle } from '../Icon/icons/IconPlayCircle';
import { IconPauseCircle } from '../Icon/icons/IconPauseCircle';
import { Icon } from '../Icon';
import { IconMenu } from '@maverick/icons/dist/IconMenu';
import { Drawer } from '../Drawer';
import { GaEvent } from '@maverick/utils';
import styles from './Header.module.scss';
import HomeMenu from '../../views/Home/components/HomeMenu/HomeMenu';

interface HeaderProps extends HeaderReduxProps {
	variant?: 'primary' | 'secondary';
	showVideoButton?: boolean;
}
export const Header: FC<HeaderProps> = ({
	variant = 'primary',
	showVideoButton = false,
	videoPlaying,
	setVideoPlaying,
}) => {
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

	const handleHomePageButtonClick = () => {
		GaEvent.HomepageButtonClick('Dine Rewards Logo');
	};

	const videoButtonIcon = videoPlaying ? IconPauseCircle : IconPlayCircle;
	const logo = variant === 'primary' ? 'img/dine-rewards-logo-white.svg' : 'img/dine-rewards-logo.svg';
	const _class = `
		${styles['container']}
		${styles[`variant-${variant}`]}
	`;

	const handleButtonClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
		const buttonId = ev.currentTarget.id;
		setDrawerOpen(true);
		GaEvent.DineRewardsSideDrawerNavigation(buttonId);
	};

	return (
		<>
			<header className={_class}>
				<div className={styles['logo']}>
					<Link href='/' onClick={handleHomePageButtonClick} tabIndex={0}>
						<img
							src={logo}
							id='dine-rewards-logo'
							data-testid='dine-rewards-logo'
							alt='Dine Rewards logo'
						/>
					</Link>
				</div>

				<div className={styles['content']}>
					{showVideoButton && (
						<div className={styles['video-button']}>
							<button
								data-testid='background-play-pause-button'
								id='background-play-pause-button'
								aria-label='background play and pause button'
								onClick={() => setVideoPlaying(!videoPlaying)}
								tabIndex={0}
							>
								<Icon icon={videoButtonIcon} customSize />
							</button>
						</div>
					)}

					<div className={styles['drawer-button']}>
						<button
							data-testid='icon-menu-button'
							id='icon-menu-button'
							aria-label='icon menu button'
							onClick={handleButtonClick}
							tabIndex={0}
						>
							<Icon icon={IconMenu} customSize />
						</button>
					</div>
				</div>
			</header>

			<Drawer isOpen={drawerOpen} onClose={() => setDrawerOpen(false)} mobileFullScreen>
				<HomeMenu setDrawerOpen={setDrawerOpen} />
			</Drawer>
		</>
	);
};
